import * as _ from 'lodash';
import React from "react";
import {getStore} from '../store';
import md5 from 'js-md5';
import sha256 from 'js-sha256';

export function isMonumentCineraire(monument) {
  return ['900-999', 'CIN'].indexOf(monument.category) >= 0;
}
export function isMonumentDouble(monument) {
  return ['700-799'].indexOf(monument.category) >= 0;
}

export function isMonumentTombale(monument) {
  return monument.kind === 'TBL' || monument.category === 'TBL';
}
export function inValidVisual(monument) {
  const ref = monument.productId.slice(0,-3)
  const reforiginal = monument.productIdOriginal.slice(0,-3)
  if (ref !== reforiginal && !monument.ismonumentspec) {
    return true
  }
  return false
}
function shuffle(tab) {
  tab.sort(function (a, b) {
    return Math.random() - Math.random()
  })
}

export function formatEmail(email) {
  const cleanEmail = email.toLowerCase().trim();
  
  return {
    clean: cleanEmail,
    md5: md5(cleanEmail),
    sha256: sha256(cleanEmail)
  };
}

export function formatToE164(countryCode, phoneNumber, foreignTown) {
  let phone = phoneNumber;
  if (!foreignTown) {
    // remove 0 if it starts with 0
    phone = phone.replace(/^0+/, '');
  }
  phone = `${countryCode}${phone.replace(/\D/g, '')}`;
  return phone;
};

function arrayRand(tableau, nb = null) {
  if (nb === null) nb = tableau.length
  /* Création du tableau temporaire de travail */
  let tmp = []
  tableau.forEach(function (e) {
    tmp.push(e)
  })
  /* Mélange */
  shuffle(tmp)
  /* Retour du 1er élément ou d'une tranche */
  if (nb === 1) {
    return tmp[0]
  } else {
    return tmp.slice(0, nb)
  }
}
export function defaultBicolorGranites(availableGranites, matchcolors) {
  let graniteslist = []
  const graniteslimit = 75
  const matchcolorsRandom = arrayRand(matchcolors)
  if (availableGranites.length <= graniteslimit) return availableGranites
  while(graniteslist.length < graniteslimit) {
    const graniteslistRef = graniteslist.map(granite => `${granite?.reference}/${granite?.gs?.reference}`)
    const availableGranitesFiltred = availableGranites.filter(granite => !graniteslistRef.includes(`${granite?.reference}/${granite?.gs?.reference}`))
    matchcolorsRandom.forEach((color, index, array) => {
      if (graniteslist.length === graniteslimit) {
        return 
      }
      const granite = availableGranitesFiltred.find(availableGranite => {
        return availableGranite.colorGranite === color.name
      })
      // const granite = granites[0]
      if(granite) {
        graniteslist.push(granite)
      }
    })
  }
  return graniteslist
}

export function convertDate (dateSelection) {
  let date = new Date(dateSelection);
  const leadZero = date.getMinutes()<10?'0':'' ;
  return (
    date.getDate() + "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    " " +
    date.getHours() +
    ":" + leadZero +
    date.getMinutes()
  );
};
export function checkToken(error,parent=false){
  if (error && error.includes("TokenExpiredError")){
    // On recharge la page si le jeton a expiré
    if (parent){
       window.parent.location = document.referrer;
    }else{
       window.location.reload();
    }
   }
}
export function getDescription(monument,granites = null){
  // Pour les granits secondaires si la description est absente on affiche une phrase de description
  const {description,graniteSecondary} = monument;
  let newDescription ="";
  if (graniteSecondary.reference && !description){
    newDescription = "Granit Secondaire " + graniteSecondary.name;
  }  else if (description){newDescription = description}

  return newDescription;
}

export function getClientIdFromFamilyUrl (fullClientId) {
  return fullClientId.split('_')[0];
}

export function isGpgFamily(){
  return false; // on désactive en attendant d'être sûr du déploiement

}

export function getMonumentCategory() {
  return getStore().getState().catalog.monumentCategory;
}
export function isGpgpFamilyConfig(client){
  const infos = window.location.pathname.split('/');
  if (infos.length >2 && infos[1] === "famille"){
    const clientId = client ? client : getClientIdFromFamilyUrl(infos[2]);
    // if (clientId && clientId ==="CA102194"){
    if (clientId && getStore().getState().user.isGpgClient ){
      return true;
    }
  }
  return false
}
export const getFamilyClientId = () => {
  const elements =window.location.pathname.split('/');
  if(elements.length >=3 && elements[1]==="famille"){
      return elements[2];
    }
  return false
}
export const checkDisplaySimpleConfig=(config) => config.configType === "catalog"

/**
 * designType: A old configurator version / B testing configurator version
 */
export function getDesignTypeConfiguration (config = false) {
 /*  const designType = (config && config.designType) ? config.designType : localStorage.getItem("gpg-configuration-type");

  if (designType && (designType === "A" || designType === "B")) {
    return designType;
  } else {
    const newdesignType = ((Math.floor(Math.random()*2)%2) === 0) ? "A" : "B";
    localStorage.setItem('gpg-configuration-type', newdesignType);
    return newdesignType;
  } */
  return "B";
};

export const isConfigurationRequested = (configuration) => (configurationQuoteRequested(configuration) || configurationOrdered(configuration))

export const configurationQuoteRequested = (configuration) => (configuration && configuration.status && configuration.status.completedAt);

export const configurationOrdered = (configuration) => ((configuration && configuration.status && configuration.status.validatedAt)||
                                                        (configuration && configuration.status && configuration.status.prevalidatedAt));

export const displayPackage = (user) =>  _.get(user,"identity.organization.displayPackagePrice");

export const pricePackageToDisplay = (user,price)=> {

  const pricePackageMonument = displayPackage(user) && price && price.selling > price.sellingWithoutPackage ?
                                 price.selling - price.sellingWithoutPackage : 0;
  return pricePackageMonument
 }

 export const totalPricePackageToDisplay = (user,config)=> {
   // On additionne le monument + la semelle et le placage , on ne peut pas utiliser le total car en cas de prix à 0
   // le calcul est mauvais
  return pricePackageToDisplay(user,config.monument.price) + pricePackageToDisplay(user,config.frame && config.frame.price)
         + pricePackageToDisplay(user,config.veneer && config.veneer.price);
 }

 export const packageInfoFamily = (user,config,notFamily = false, from) =>{
  const packagePrice = totalPricePackageToDisplay(user,config);
  if(notFamily) return ('');
  if (from === 'FbigDetail'){
    if (packagePrice){
      // return (`Les frais de pose sont estimés à ${packagePrice} €`);
      return (<>
        <p><span>Les frais de poses sont estimés à {packagePrice} € </span> mais sont amenés à être modifiés en fonction de la surface au sol du lieu de pose.</p>
      </>)
    } else{
      //return ("Devis à valider avec votre marbrier conseil")
      return (
        <>
          <p><span>Ce prix n'inclut pas les frais de pose</span> qui seront calculés avec votre marbrier conseil.</p>
        </>
      )
    }
  } else {
    return ('* Prix indicatif à valider avec votre marbrier')
  }
 }

export function getReactAppExtranet(path = "") {
  return `${process.env.REACT_APP_EXTRANET || 'http://localhost:3040'}${path}`;
}
export const getReactAppFamily = () => process.env.REACT_APP_FAMILY || 'http://localhost:3011';

export const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const isStandardClient = (clientId) => clientId.startsWith("CA") && !(clientId.endsWith("-SF") || clientId.includes("-SF-"))

export const isFamilySpaceOn = (user)=> user && user.identity && user.identity.organization && user.identity.organization.familySpace ===1;

export const validUrl = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const hexToRgb = hex => hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,(m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16))

export const replaceMultiplicationSymbol = (string = "") => {
  return string.replace(/[×x]/g, ' X ');
}