import {getStore} from '../store'
import {addMessage, removeMessage} from './message.service';
import * as _ from 'lodash';

/**
 * Ce check est appelé très régulièrement parce que beaucoup d'actions peuvent
 * déclencher un dépassement / une fin de dépassement de limites.
 *
 * Ce check est assez lourd parce qu'il itère sur toutes les faces et
 * lance 2 actions redux systèmatiquement (pour afficher ou masquer les messages).
 *
 * Ce check n'a pas besoin d'afficher un warning immédiatement et peut attendre
 * 500ms avant de prévenir l'utilisateur qu'il a dépassé la limite.
 *
 * D'où le debounce.
 *
 */
export const warnOnTooManyItems = _.debounce(checkAndWarnOnTooManyItems, 500);

function checkAndWarnOnTooManyItems() {

  const configuratorCurrentState = getStore().getState().configurator.current;
  if (!configuratorCurrentState) {
    // On a quitté le configurateur avant l'appel à cette fonction à cause du debounce
    return;
  }

  const {maxLinesReached, maxPatternsReached, face} = checkPatternsCount(configuratorCurrentState);

  if (maxLinesReached) {
    addMessage({
      id: 'tooManyLines',
      className: 'warning',
      innerHTML: `Vous avez atteint le nombre maximum de ${maxLinesReached > 0? maxLinesReached: ''}
      lignes de gravures sur la face ${face.piece.name} ${face.profile.name},
      certaines n'apparaitront pas sur le rendu 3D et les BAT.`
    })
  } else {
    removeMessage('tooManyLines')
  }
  if (maxPatternsReached) {
    addMessage({
      id: 'tooManyPatterns',
      className: 'warning',
      innerHTML: `Vous avez atteint le nombre maximum de ${maxPatternsReached > 0? maxPatternsReached: ''} 
      motifs sur la face ${face.piece.name} ${face.profile.name},
      certains n'apparaitront pas sur le rendu 3D et les BAT.`
    })
  } else {
    removeMessage('tooManyPatterns')
  }
}

/**
 * Vérifie que la config ne contient pas trop de gravures ou motifs
 * par rapport aux spécifications du monument.
 *
 * Renvoie un objet avec soit les clefs à 0 (RAS) soit les clef à une
 * valeur qui correspond au max qui a été dépassé (c'est tout ce dont on
 * a besoin pour le warning)
 *
 * @param current : Etat actuel du configurateur (state.configurator.current)
 * @returns {
 * maxPatternsReached:Number,
 * maxLinesReached:Number
 * }
 *
 */
export function checkPatternsCount(current) {

  const engravings = current.configuration.engravings,
    patterns = current.configuration.patterns,
    faces = current.options.faces;

  function getEngravingsForFace(face) {
    return _.chain(engravings)
      .filter(engraving => !!(engraving.text || '').replace(/\s+/, ''))
      .filter(engraving => !engraving.reservation)
      .filter({
        face: {
          piece: face.piece.reference,
          profile: face.profile.reference
        }
      })
      .value();
  }

  function getPatternsForFace(face) {
    return _.chain(patterns)
      .filter(pattern => !pattern.imprint)
      .filter({
        face: {
          piece: face.piece.reference,
          profile: face.profile.reference
        }
      })
      .value();
  }

  function getEngravingLinesCount(face) {
    return _.sum(getEngravingsForFace(face)
      .map(engraving => _.filter(engraving.lines, 'text').length))
  }

  return _.chain(faces)
      .map(face => ({
        face,
        maxLinesReached: 0, // Plus de max getEngravingLinesCount(face) > face.maxLabels.engravings ? (face.maxLabels.engravings || -1) : 0,
        maxPatternsReached: 0, // Plus de max getPatternsForFace(face).length > face.maxLabels.patterns ? (face.maxLabels.patterns || -1) : 0,
      }))
      .find(({maxLinesReached, maxPatternsReached}) => maxLinesReached || maxPatternsReached)
      .value() || {maxLinesReached: 0, maxPatternsReached: 0, face: null};
}

// Le fait que ce soit asynchrone permet de l'appeller
// dans les actions avant que l'action ai lieu.
// Le debounce permet de ne pas trop se soucier des performances du check,
// ce check déclenche systématiquement une action redux.
export const checkVeneerAndFrameDimensions = _.debounce(doCheckVeneerAndFrameDimensions, 500);
export const displayVeneerWarning = _.debounce(doDisplayVeneerWarning, 500);
export const displayLayoutBaseHeightWarning = _.debounce(doDisplayLayoutBaseHeightWarning, 500);

/**
 * Vérifie que le placage du monument ne nécéssite pas de découpe.
 * Si une découpe est nécessaire, l'utilisateur est informé (max 1 fois par
 * session).
 */
let lastTimeWeCheckedItWasOK = true;
let lastTimeWarningVeneer =  true;
const maxSizeOfPart = 230;

export const messageDimensionsTooBig = `
  Pour des raisons d'approvisionnement et de fragilité, 
  les pièces > à ${maxSizeOfPart}cm seront réalisées en 2 parties
`;
export const displayWarningVeneerDisplay = `Attention, le visuel affichant les 
placages ne correspond pas aux 
dimensions entrées. Celles-ci seront
actualisées dans votre commande`;

export const displayWarningLayoutBaseHeightDisplay = `Attention, le visuel affichant recette ou 
soubassement ne correspond pas aux 
dimensions entrées. Celles-ci seront
actualisées dans votre commande`;

export function isVeneerDimensionsTooBig() {
  const veneer = _.get(getStore().getState(),
    'configurator.current.configuration.veneer');
  const lengths = _.map(['left', 'right', 'back', 'front'],
    side => _.get(veneer, `dimensions.${side}.length`));
  return isDimensionsTooBig(lengths);
}

export function isFrameDimensionsTooBig() {
  const frame = _.get(getStore().getState(),
    'configurator.current.configuration.frame');
  const layouts = _.get(getStore().getState(),
    'configurator.current.options.layouts');
  const layoutReference = _.get(getStore().getState(),
    'configurator.current.configuration.monument.layout');
  const layout = _.find(layouts, {reference: layoutReference}) || {size: {width: 0, depth: 0}};
  // La largeur maxi est toujours celle de la plinthe de devant
  const trueMaxWidth = frame.width;
  // Si parpaing arrière, la profondeur des plinthes est celle de la recette (a priori)
  // Si closoir arrière, la profondeur des plinthes est la profondeur totale moins la vue avant
  const defaultFrontDepth = (frame.depth - layout.size.depth) / 2;
  const trueMaxDepth = (frame.layout === 'parpaing' ? layout.size.depth -10 : (frame.depth - (frame.frontDepth || defaultFrontDepth)));
  return isDimensionsTooBig([trueMaxWidth, trueMaxDepth]);
}

function isDimensionsTooBig(lengths) {
  return _.some(lengths, length => length > maxSizeOfPart);
}

function doCheckVeneerAndFrameDimensions() {
  if (!getStore().getState().configurator.current) {
    // On a quitté le configurateur avant l'appel à cette fonction à cause du debounce
    return;
  }

  if (isVeneerDimensionsTooBig() || isFrameDimensionsTooBig()) {
    if (lastTimeWeCheckedItWasOK) {
      lastTimeWeCheckedItWasOK = false;
      addMessage({
        id: 'veneerOrFrameSplit',
        className: 'info',
        innerHTML: messageDimensionsTooBig
      })
    }
  } else {
    if (!lastTimeWeCheckedItWasOK) {
    removeMessage('veneerOrFrameSplit')
    }
    lastTimeWeCheckedItWasOK = true;
  }
}

function doDisplayVeneerWarning (){
  if (lastTimeWarningVeneer) {
    lastTimeWarningVeneer = false;
    addMessage({
      id: 'veneerWarning',
      className: ' measures ',
      innerHTML: displayWarningVeneerDisplay
    })
} else {
  if (!lastTimeWarningVeneer) {
  removeMessage('veneerWarning')
  }
  lastTimeWarningVeneer = true;
}

}

function doDisplayLayoutBaseHeightWarning (showmessage = false){
  if (showmessage) {
    lastTimeWarningVeneer = false;
    addMessage({
      id: 'visualWarning',
      className: ' measures ',
      innerHTML: displayWarningLayoutBaseHeightDisplay
    })
} else {
  if (!showmessage) {
  removeMessage('visualWarning')
  }
  showmessage = true;
}

}

export const isConfigRequested = (showMessage = true) => {
  const status = _.get(
    getStore().getState(),
    'configurator.current.configuration.status'
  );
  const blocked =_.get(
    getStore().getState(),
    'configurator.current.blocked'
   );
  
  if (status && ( status.completedAt || status.validatedAt ) === null
      && !blocked
  ) {
    return false;
  }
  else {
    if (showMessage) {
      removeMessage('isConfigRequested')
      addMessage({
        id: 'isConfigRequested',
        className: 'warning',
        innerHTML: "Cette configuration a fait l'objet d'une demande auprès de GPG Granit, veuillez créer une variante pour la modifier."
      });
    }
    
    return true;
  }
}
